/* Desktop Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: HoneyScript;
  src: url(HoneyScript-Light.ttf);
}
h2,
h3,
h4,
h5,
h6 {
  font-family: HoneyScript;
}
h2 {
  font-size: 3rem !important;
}
h3 {
  font-size: 3rem !important;
}
ul {
  margin-bottom: 0 !important;
}
a {
  color: unset !important;
}

.grey-background {
  background: #cdcdcd;
}

.App {
  overflow-x: hidden;
}

/* desktop style  */

img {
  width: 100%;
  height: 100%;
}
.desktop-hidden {
  display: none;
}
.mobile-hidden {
  display: block;
}
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

/* header code here */
.nav-container {
  position: fixed;
  top: 0px;
  z-index: 10;
  max-width: 100%;
  width: 100%;
  box-shadow: 0px 2px 8px 0px #0000001f;
}
nav {
  height: 60px;
  background-color: white;
  width: -webkit-fill-available;
  max-width: 100vw;
  z-index: 5;
}
nav > ul {
  display: inline-block;
  width: 120px;
  text-align: center;
  padding: 10px;
  padding-top: 17px;
  height: 100%;
  cursor: pointer;
}
nav > ul:hover {
  color: #34908b;
}

nav > ul:last-child {
  float: right;
  background-color: #479ca8;
  color: white;
}
nav > ul:last-child:hover {
  background-color: #3a808a;
}
nav > h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mobile-nav {
  position: absolute;
  top: 60px;
  z-index: 4;
  transition: 0.5s;
  height: 90vh;
}
.mobile-nav-container {
  display: relative;
}
.mobile-nav-option {
  color: white;
  cursor: pointer;
}
.mobile-nav-inner {
  background: #294242;
}

/* end of header styles */

/* footer styles */
.footer {
  background: #383838;
  width: 100vw;
  height: auto;
  min-height: 50px;
  color: white;
  text-align: center;
  padding: 10px;
}
/* end of footer styles */

/* User Logo styles */

.user-img-bg {
  position: relative;
  width: 100vw;
  height: 200px;
}

.user-img-overlay {
  width: 100vw;
  height: 200px;
  background: #5b8383;
  z-index: 2;
}

.user-img-centering {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.user-img-interface {
  height: 225px;
  width: 225px;
  background: white;
  z-index: 1;
}

.user-img {
  height: 200px;
  width: 200px;
  box-shadow: 0 0 11px 0px black;
  z-index: 3;
}

/* end of user logo styles */

/* portfolio page styles  */

.container {
  width: 100vw;
  height: 100%;
  padding: 10px;
}

.container .portfolio {
  background-color: white;
  border-radius: 10px;
  width: 100%;
}

/* end of portfolio page styles */

/* Contact us */
.contact-us-form-container {
  background: white;
  width: 100%;
  height: 100%;
}
.contact-form {
  width: auto;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  padding: 20px;
}

/* End of Contact us */

/* mobile styles */

/* animations */

/* Underline From Center */
.hvr-underline-from-center {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-underline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: 0;
  background: rgb(255, 0, 159);
  background: linear-gradient(
    90deg,
    #34908b 0%,
    #428683 33%,
    #2a7c78 62%,
    #34908b 100%
  );
  height: 2px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-underline-from-center:hover:before,
.hvr-underline-from-center:focus:before,
.hvr-underline-from-center:active:before {
  left: 0;
  right: 0;
}

@media only screen and (max-width: 1200px) {
  .mobile-hidden {
    display: none;
  }
  .desktop-hidden {
    display: block;
  }
  nav > h2 {
    text-align: left;
    margin-left: 10px;
    left: 0;
    transform: translate(0%, -50%);
  }
  .container {
    width: unset;
  }
  .homepage-container {
    text-align: center;
  }
}
